import {createSelector} from "@reduxjs/toolkit";

export const orgsLoadingSelector = ({ organizations }) => organizations.orgsLoading;
export const orgSelector = orgId => ({ organizations }) => organizations.orgMap[orgId];
const orgMapSelector = ({ organizations }) => organizations.orgMap

export const orgsSelector = () => createSelector(
    orgMapSelector,
    (orgMap) => {
        return Object.values(orgMap);
    }
);
