export function saveLocal(key, value, asJson) {
    // console.log("saving", key, value, asJson);
    if (value && asJson) {
        // console.log("saving", key);
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.setItem(key, value);
    }
}

export function getLocal(key, asJson) {
    var value = localStorage.getItem(key);

    if (value && value !== "undefined" && asJson) {
        return JSON.parse(value);
    }

    return value;
}