import {createSlice} from "@reduxjs/toolkit";

const INITIAL_STATE = {
    orgsLoading: false,
    orgMap: {},
};

const orgsSlice = createSlice({
    name: 'organizations',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.orgsLoading = action.payload;
        },
        orgsSuccess(state, action) {
            state.orgsLoading = false;
            state.orgMap = action.payload.reduce((ret, org) => {
                ret[org.id] = org;
                return ret;
            }, {});
        },
        orgSuccess(state, action) {
            state.orgMap[action.payload.id] = action.payload;
        }
    }
});

export const { loading, orgsSuccess, orgSuccess } = orgsSlice.actions;

export default orgsSlice.reducer;

export const loadOrganizations = (email, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.getOrganizations(email)
            .then(orgs => {
                console.log("loadOrganizations", orgs);
                return dispatch(orgsSuccess(orgs));
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const saveOrganization = (organization, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.saveOrganization(organization)
            .then(org => {
                return dispatch(orgSuccess(org));
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const loadOrganizationContacts = (orgId, setLoading, setContacts, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.getOrganization(orgId)
            .then(org => {
                setLoading(false);
                setContacts(org.emails);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);
