import React, {useEffect, useMemo} from 'react';
import cx from 'classnames';
import {Card} from "@mui/material";
import {isAfter} from "date-fns";

import TrackableDiv from "../_common/trackables/TrackableDiv";
import {formatPennies} from "../../utils/money";

import checkboxOff from "../../img/checkbox-off.svg";
import checkboxOn from "../../img/checkbox-on.svg";
import earlyBird from "../../img/early_bird.svg";

interface Props {
    tierName: string;
    earlyBirdSelected: boolean;
    setEarlyBirdSelected: (selected: boolean) => void;
    savingsInPennies: number;
    priceInPennies: number;
    subtotalInPennies: number;
    quantity: number;
}

const EarlyBird: React.FC<Props> = ({ priceInPennies, savingsInPennies, tierName, subtotalInPennies, earlyBirdSelected, setEarlyBirdSelected, quantity }) => {
    const forceEarlybird = useMemo(() => {
        const now = new Date();
        const year = now.getFullYear();
        return isAfter(now, new Date(`${year}-10-01`));
    }, []);

    useEffect(() => {
        setEarlyBirdSelected(true);
    }, [forceEarlybird, setEarlyBirdSelected]);

    return (
        <Card className="mt-4 w-full">
            <div className="bg-green p-3 md:p-4 text-white">
                <div className="flex mx-2 md:mx-4 items-center">
                    <div className="mr-3">
                        <img src={earlyBird} alt="Early Bird" className="w-6 h-6" />
                    </div>
                    <div className="font-medium text-sm md:text-lg text-white uppercase">
                        Early Bird Special
                    </div>
                </div>
                <div>
                    <ul className="list-disc pl-7 md:pl-9 text-xs md:text-base mt-2 font-lato">
                        <li className="mb-2">
                            Get next year's membership at today's price!
                        </li>
                        <li className="mb-2">
                            Purchases after Oct. 1st must include Membership for the following year.
                        </li>
                        <li>
                            Your {tierName} will be valid Today - Dec 31, {new Date().getFullYear() + 1}
                        </li>
                    </ul>
                </div>
                <TrackableDiv eventName="early-bird-click" className={cx("rounded-md p-4 flex cursor-pointer items-center mt-4 transition-all", {
                    "bg-green1 text-white": !earlyBirdSelected,
                    "bg-white text-green": earlyBirdSelected
                })} onClick={() => {
                    if (!forceEarlybird) {
                        setEarlyBirdSelected(!earlyBirdSelected)
                    }
                }}>
                    <div className="mr-3">
                        <img src={earlyBirdSelected ? checkboxOn : checkboxOff} alt="Select Early Bird" className="w-5 h-5" />
                    </div>
                    <div className="text-sm md:text-xl uppercase">
                        Add Early Bird Special
                    </div>
                    <div className="flex-1 text-right text-sm md:text-lg">
                        + {formatPennies(priceInPennies)}{quantity > 1 && ` (x${quantity})`}
                    </div>
                </TrackableDiv>
                {earlyBirdSelected &&
                    <div className="rounded-md mt-4 bg-green2 py-2 px-4 flex uppercase text-base md:text-lg font-light">
                        <div>
                            Subtotal
                        </div>
                        <div className="flex-1 text-right font-bold">
                            {formatPennies(subtotalInPennies)}{quantity > 1 && ` (x${quantity})`}
                        </div>
                    </div>
                }
            </div>
        </Card>
    )
}

export default EarlyBird;
