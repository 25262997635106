import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';

import Home from "./components/home";
import theme from './theme';
import rootReducer from './redux';
import api from './api';
import AuthListener from "./components/auth";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api }
            },
            serializableCheck: false,
        }),
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS === "true",
});

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Router>
                        <Home />
                        <AuthListener />
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
