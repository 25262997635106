import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';

import {penniesToDollars} from "../../utils/money";

import checkboxOff from "../../img/checkbox-off.svg";
import checkboxOn from "../../img/checkbox-on.svg";
import add from "../../img/add.svg";
import subtract from "../../img/subtract.svg";
import subtractOff from "../../img/subtract-off.svg";
import TrackableIconButton from "../_common/trackables/TrackableIconButton";

export interface MembershipTierType {
    id: string;
    name: string;
    description: string;
    price: {
        annualInPennies: number;
        monthlyInPennies?: number;
    },
    icon?: any;
    whatsIncludedLink: string;
    allowMultiple: boolean;
}

interface Props {
    tier: MembershipTierType;
    selected: boolean;
    quantity?: number;
    onAdd?: () => void;
    onSubtract?: () => void;
}

const MembershipTier: React.FC<Props> = ({ tier, selected = false, quantity = 1, onAdd = () => {}, onSubtract = () => {} }) => {
    return (
        <div className={cx("flex p-3 md:p-4 cursor-pointer border-2 rounded-md border-solid", {
            "border-primary": selected,
            "border-transparent": !selected
        })}>
            <div className="pt-1">
                <img src={selected ? checkboxOn : checkboxOff} alt="checkbox" className="w-5 h-5" />
            </div>
            <div className="flex-1 ml-3 md:mr-4">
                <div className="flex mb-1 items-center">
                    {tier.icon &&
                        <div className="mr-1">
                            <img src={tier.icon} alt={tier.name} className="w-6 h-6" />
                        </div>
                    }
                    <div className="uppercase text-primary text-sm md:text-xl flex-1">
                        {tier.name}
                    </div>
                    <div className="text-primary font-light text-sm md:text-base md:hidden">
                        {numeral(penniesToDollars(tier.price.annualInPennies)).format("$0,0.00")}/yr
                    </div>
                </div>
                <div className="mb-1 font-normal text-graydark font-lato text-xs md:text-base" dangerouslySetInnerHTML={{__html: tier.description}} />
                <div>
                    <a href={tier.whatsIncludedLink} target="_blank" rel="noopener noreferrer" className="text-secondary font-normal font-lato text-xs md:text-base">
                        What's included
                    </a>
                </div>
                {tier.allowMultiple &&
                    <div className="border-solid border-0 border-t border-graymedium mt-3 pt-3">
                        <div className="mb-2 font-normal text-graydark font-lato text-xs md:text-base">
                            Supporting Membership is priced per location, therefore organizations with multiple locations must purchase memberships for each location in order to ensure complete coverage of the organization. Organizations with 24 or more locations are encouraged to upgrade to Elite membership to receive maximum value.
                        </div>
                        <div className="uppercase text-primary text-sm mb-2 font-medium">
                            # of Locations
                        </div>
                        <div className="flex">
                            <div className="rounded-md bg-graylight p-3 flex items-center">
                                <div>
                                    <TrackableIconButton eventName="quantity-subtract-click" params={{ quantity }} onClick={onSubtract}>
                                        <img src={quantity > 1 ? subtract : subtractOff} alt="Subtract Quantity" className="w-5 h-5" />
                                    </TrackableIconButton>
                                </div>
                                <div className="w-8 text-xl text-primary font-medium text-center">
                                    {quantity}
                                </div>
                                <div>
                                    <TrackableIconButton eventName="quantity-add-click" params={{ quantity }} onClick={onAdd}>
                                        <img src={add} alt="Add Quantity" className="w-5 h-5" />
                                    </TrackableIconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="text-primary font-light text-sm md:text-lg hidden md:block">
                {numeral(penniesToDollars(tier.price.annualInPennies)).format("$0,0.00")}/yr
            </div>
        </div>
    )
}

export default MembershipTier;
