import React, { useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";
import { PaymentMethod } from "@stripe/stripe-js";
import {
    CircularProgress,
} from "@mui/material";
import {
    Payment,
    AccountBalance,
    Payments,
    Add
} from "@mui/icons-material";
// @ts-ignore
import {PaymentMethodType} from "@devsontap/nica-api/core/models/enums";

import { fetchPaymentMethods } from "../../../redux/paymentMethods";
import { paymentMethodsSelector, paymentMethodsLoadingSelector } from "../../../redux/paymentMethods/selectors";
import NicaRadioButton from "../radioButton";
import StripePaymentElement from "../stripePaymentElement";
import NICADialog from "../dialog";
import TrackableButton from "../trackables/TrackableButton";
import api from "../../../api";

interface Props {
    selectedPaymentMethodId: any;
    onChange: (paymentMethodId: any) => void;
}

const PaymentMethodList: React.FC<Props> = ({ selectedPaymentMethodId, onChange }) => {
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
    const [addingPayment, setAddingPayment] = useState(false);
    const submitRef = useRef<HTMLInputElement>(null);
    const paymentMethodsLoading = useSelector(paymentMethodsLoadingSelector);
    const paymentMethods = useSelector(paymentMethodsSelector());
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!paymentMethods) {
            // @ts-ignore
            dispatch(fetchPaymentMethods(enqueueSnackbar));
        }
    }, [dispatch, enqueueSnackbar, paymentMethods]);

    return (
        <div>
            {!paymentMethods || paymentMethodsLoading ?
                <div className="flex justify-center">
                    <CircularProgress size={35} />
                </div> :
                <div>
                    <div className="mb-4">
                        <TrackableButton eventName="add-payment-method-click" variant="text" onClick={() => setShowAddPaymentMethod(true)}>
                            <Add /> Add Payment Method
                        </TrackableButton>
                    </div>
                    {paymentMethods?.map((paymentMethod: PaymentMethod)=> {
                        return (
                            <div key={paymentMethod.id} className="mb-0">
                                <NicaRadioButton selected={paymentMethod.id === selectedPaymentMethodId} onSelected={() => {
                                    api.logEvent("payment-method-click", { type: paymentMethod.type });
                                    onChange(paymentMethod.id);
                                }}>
                                    {paymentMethod.type === "card" ?
                                        <div className="capitalize">
                                            <Payment /> {paymentMethod.card?.brand} - {paymentMethod.card?.last4}
                                        </div> :
                                        <div className="capitalize">
                                            <AccountBalance /> {paymentMethod.us_bank_account?.bank_name} - {paymentMethod.us_bank_account?.last4}
                                        </div>
                                    }
                                </NicaRadioButton>
                            </div>
                        )
                    })}
                    <NicaRadioButton selected={PaymentMethodType.CHECK.value === selectedPaymentMethodId} onSelected={() => {
                        const type = PaymentMethodType.CHECK.value;
                        api.logEvent("payment-method-click", { type });
                        onChange(type);
                    }}>
                        <div className="capitalize">
                            <Payments /> Pay By Check
                        </div>
                    </NicaRadioButton>
                </div>
            }
            <NICADialog
                open={showAddPaymentMethod}
                title="Add Payment Method"
                maxWidth="lg"
                onClose={() => setShowAddPaymentMethod(false)}
                actions={[
                    { label: "Cancel", onClick: () => setShowAddPaymentMethod(false) },
                    {
                        label: "Submit",
                        loading: addingPayment,
                        onClick: () => {
                            api.logEvent("add-payment-method-submit-click");
                            setAddingPayment(true);
                            submitRef?.current?.click();
                        },
                    },
                ]}
            >
                <StripePaymentElement onComplete={() => {
                    setAddingPayment(false);
                    setShowAddPaymentMethod(false);
                    // @ts-ignore
                    dispatch(fetchPaymentMethods(enqueueSnackbar));
                }} submitRef={submitRef} />
            </NICADialog>
        </div>
    )
};

export default PaymentMethodList;
