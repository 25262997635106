import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';

import {penniesToDollars} from "../../utils/money";

import checkboxOff from "../../img/checkbox-off.svg";
import checkboxOn from "../../img/checkbox-on.svg";

interface Props {
    name: string;
    description: string;
    amountInPennies?: number;
    savingsInPennies?: number;
    selected: boolean,
    quantity: number;
}

const PaymentOption: React.FC<Props> = ({ name, description, amountInPennies, savingsInPennies, selected, quantity }) => {
    return (
        <div className={cx("flex p-3 md:p-4 border-2 rounded-md border-solid", {
            "border-primary": selected,
            "border-transparent": !selected,
            "cursor-pointer": amountInPennies !== undefined
        })}>
            {amountInPennies !== undefined &&
                <div className="pt-1">
                    <img src={selected ? checkboxOn : checkboxOff} alt="checkbox" className="w-4 h-4 md:w-5 md:h-5" />
                </div>
            }
            <div className="flex-1 mx-2 md:ml-3 md:mr-4">
                <div className={cx("flex mb-1 items-center font-medium uppercase text-sm md:text-xl", {
                    "text-primary": amountInPennies !== undefined,
                    "text-graymedium": amountInPennies === undefined
                })}>
                    {name}
                </div>
                {amountInPennies !== undefined &&
                    <div className="mb-1 font-normal text-graydark font-lato text-xs md:text-base">
                        {description}
                    </div>
                }
            </div>
            <div className="text-primary font-light text-sm md:text-lg flex flex-col items-end">
                {amountInPennies !== undefined ?
                    <div>
                        {numeral(penniesToDollars(amountInPennies)).format("$0,0.00")}{quantity > 1 && ` (x${quantity})`}
                    </div> :
                    <div className="text-graymedium">
                        N/A
                    </div>
                }
                {savingsInPennies !== undefined && savingsInPennies > 0 &&
                    <div className="bg-green rounded-full uppercase font-medium text-white px-[6px] md:py-1 md:px-2 text-[0.5rem] md:text-sm mt-1">
                        Save {numeral(penniesToDollars(savingsInPennies)).format("$0,0.00")}
                    </div>
                }
            </div>

        </div>
    )
}

export default PaymentOption;
