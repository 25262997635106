import React from 'react';
import {Button, Card} from "@mui/material";

const ThankYou: React.FC = () => {
    return (
        <div className="flex justify-center w-full">
            <Card className="py-4 md:py-6 px-4 md:px-10 w-full md:w-[600px]">
                <div className="text-center text-primary uppercase text-base md:text-lg font-medium mb-4 font-oswald">
                    Thank you for your membership purchase!
                </div>
                <div className="font-lato">
                    Your support allows us to continue supporting infusion providers through advocacy, resources, and
                    education. Please reach out to us at <a href="mailto:membership@infusioncenter.org">membership@infusioncenter.org</a> to
                    learn more about your benefits and how to get involved.
                </div>
                <div className="mt-4 font-lato">
                    You can manage your Membership(s) and assign Member Locations from the Admin Portal.
                </div>
                <div className="flex justify-center mt-4">
                    <Button variant="contained" color="primary" href={`${process.env.REACT_APP_ADMIN_URL}/memberships`}>
                        Go to Admin Portal
                    </Button>
                </div>
            </Card>
        </div>
    )
}

export default ThankYou;
