import { combineReducers } from 'redux'

import checkout from './checkout';
import login from './login';
import organizations from './organizations';
import paymentMethods from './paymentMethods';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    checkout,
    login,
    organizations,
    paymentMethods,
});

export default RootReducer;
