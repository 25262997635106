import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    fetchingPlaidLinkToken: false,
    plaidLinkToken: null,
    bankData: null,
    stripeBankAccountToken: null,
    checkingOut: false,
    success: false,
    discountCodeLoading: false,
    discountCode: null,
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: INITIAL_STATE,
    reducers: {
        fetchingPlaidLinkToken(state, action) {
            state.fetchingPlaidLinkToken = action.payload;
        },
        plaidLinkTokenSuccess(state, action) {
            state.plaidLinkToken = action.payload;
        },
        stripeBankAccountTokenSuccess(state, action) {
            state.stripeBankAccountToken = action.payload;
        },
        bankDataSuccess(state, action) {
            state.bankData = action.payload;
        },
        resetBankInfo(state) {
            state.bankData = null;
            state.plaidLinkToken = null;
            state.stripeBankAccountToken = null;
        },
        checkingOut(state, action) {
            state.checkingOut = action.payload;
        },
        checkoutSuccess(state, action) {
            state.success = action.payload;
        },
        discountCodeLoading(state, action) {
            state.discountCodeLoading = action.payload;
        },
        discountCodeSuccess(state, action) {
            state.discountCodeLoading = false;
            state.discountCode = action.payload;
        }
    }
});

export const {
    fetchingPlaidLinkToken, plaidLinkTokenSuccess, bankDataSuccess, stripeBankAccountTokenSuccess, resetBankInfo,
    checkingOut, checkoutSuccess, discountCodeLoading, discountCodeSuccess
} = checkoutSlice.actions;

export default checkoutSlice.reducer

// CUSTOM THUNK ACTIONS

export const fetchPlaidLinkToken = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(fetchingPlaidLinkToken(true));
        return api.getPlaidLinkToken()
            .then(response => {
                dispatch(plaidLinkTokenSuccess(response.data));
                dispatch(fetchingPlaidLinkToken(false));
            })
            .catch(err => {
                console.error('Error linking bank account:', err.message);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(fetchingPlaidLinkToken(false));
            });
    }
);

export const exchangePlaidPublicToken = (publicToken, metadata, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(fetchingPlaidLinkToken(true));
        return api.exchangePlaidPublicToken({
            public_token: publicToken,
            account_id: metadata.accounts[0].id
        })
            .then(response => {
                dispatch(stripeBankAccountTokenSuccess(response.data));
                dispatch(bankDataSuccess({
                    name: metadata.institution.name,
                    accountType: metadata.accounts[0].subtype,
                    accountNumber: `••••••••••••${metadata.accounts[0].mask}`
                }))
                dispatch(fetchingPlaidLinkToken(false));
            })
            .catch(err => {
                console.error('Error linking bank account:', err.message);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(fetchingPlaidLinkToken(false));
            });
    }
);

export const checkout = (orgId, fullName, companyName, title, phoneNumber, phoneExt, membershipTier, quantity, earlyBirdSelected, paymentMethod, paymentType, autoRenew, totalInPennies, discountCode, paymentMethodId, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(checkingOut(true));
        return api.checkout(orgId, fullName, companyName, title, phoneNumber, phoneExt, membershipTier, quantity, earlyBirdSelected, paymentMethod, paymentType, autoRenew, totalInPennies, discountCode, paymentMethodId)
            .then(() => {
                dispatch(checkingOut(false));
                dispatch(checkoutSuccess(true));
                enqueueSnackbar("Payment Successful", {variant: 'success'});
            })
            .catch(err => {
                console.error('Error checking out:', err.message);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(checkingOut(false));
            });
    }
);

export const loadDiscountCode = (slug, membershipType, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(discountCodeLoading(true));

        const userEmail = getState().login.claims.email;
        return api.discountCodes.getBySlug(slug)
            .then(discountCodes => {
                const code = discountCodes[0];
                if (code) {
                    if (!code.restrictByEmails.length || code.restrictByEmails.includes(userEmail)) {
                        if ((!code.restrictByPurchasables.length && !code.restrictByMembershipTypes.length) || code.restrictByMembershipTypes.includes(membershipType)) {
                            enqueueSnackbar(`Discount code ${slug} applied!`, {variant: 'success'});
                            return dispatch(discountCodeSuccess(code));
                        }
                    }
                }
                enqueueSnackbar("Not a valid discount code", {variant: 'error'});
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar(e.message, {variant: 'error'});
            })
            .finally(() => {
                dispatch(discountCodeLoading(false));
            })
    }
)

