import React, { useRef, forwardRef, useEffect } from "react";
import TextField, {OutlinedTextFieldProps} from "@mui/material/TextField";

interface Props extends OutlinedTextFieldProps {
    setValue: (name: string, value: string) => void;
}

const PhoneNumberTextField: React.FC<Props> = forwardRef(({ value, onChange, setValue, ...otherProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFormattedChange = (event: any) => {
        let value: number|string = "";
        if (`${event.target.value}`.length > 0) {
            const pennies = parseInt(event.target.value.replace(/[^0-9]/g, ""), 10);
            value = pennies;
        }

        if (inputRef.current) {
            inputRef.current.value = _formatValue(value.toString());
        }

        // @ts-ignore
        onChange({
            ...event,
            target: {
                ...event.target,
                value
            }
        });

        setValue && setValue(event.target.name, value.toString());
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = _formatValue(value as string);
        }
    }, [inputRef, value]);

    return (
        <div>
            <TextField
                inputRef={inputRef}
                onChange={handleFormattedChange}
                {...otherProps}
            />
        </div>

    );
});

function _formatValue(value: string) {
    let numbers = "";
    if (value) {
        numbers = value.replace(/[^0-9]+/g, "");
    }

    let formatted = numbers;
    if (numbers.length > 6) {
        formatted = `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6, 10)}`;
    } else if (numbers.length > 3) {
        formatted = `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}`;
    } else if (numbers.length > 0) {
        formatted = `(${numbers.substring(0, 3)}`;
    }

    // return {
    //     formatted,
    //     raw: numbers.substring(0, 10)
    // };
    return formatted;
}

export default PhoneNumberTextField;
