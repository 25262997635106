import React, { useMemo, useState, useEffect } from "react";
import { Elements, useElements, PaymentElement, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, PaymentIntent } from "@stripe/stripe-js";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { fetchSetupIntent } from "../../../redux/paymentMethods";

interface StripePaymentElementsProps {
    submitRef: React.Ref<HTMLInputElement>;
    onComplete: () => void;
}

const StripePaymentElement: React.FC<StripePaymentElementsProps> = ({ submitRef, onComplete }) => {
    const [intent, setIntent] = useState<PaymentIntent | null>(null);
    const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY as string), []);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(
            // @ts-ignore
            fetchSetupIntent(enqueueSnackbar, (intent: PaymentIntent) => {
                setIntent(intent);
            })
        );
    }, [dispatch, enqueueSnackbar]);

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret: intent?.client_secret || "",
        appearance,
    };

    return (
        <div>
            {!intent ? (
                <div className="flex w-full justify-center">
                    <CircularProgress size={45} />
                </div>
            ) : (
                // @ts-ignore
                <Elements stripe={stripePromise} options={options}>
                    <StripePaymentContainer onComplete={onComplete} submitRef={submitRef} />
                </Elements>
            )}
        </div>
    );
};

interface StripePaymentContainerProps {
    submitRef: React.Ref<HTMLInputElement>;
    onComplete: () => void;
}

const StripePaymentContainer: React.FC<StripePaymentContainerProps> = ({ submitRef, onComplete }) => {
    const stripe = useStripe();
    const elements = useElements();

    const paymentElementOptions = {
        layout: "tabs",
    };

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: document.location.href,
            },
            redirect: "if_required",
        });
        onComplete();

        if (error) {
            console.log("error", error);
        }
    };

    return (
        <div className="w-full sm:min-w-[500px]">
            <input type="hidden" ref={submitRef} onClick={onSubmit} />
            {/* @ts-ignore */}
            <PaymentElement id="payment-element" options={paymentElementOptions} />
        </div>
    );
};

export default StripePaymentElement;
