import diamond from "../img/diamond.svg";

export const MEMBERSHIP_TIER_ELITE = {
    id: "elite",
    name: "Elite Membership",
    icon: diamond,
    price: {
        annualInPennies: 700000,
        monthlyInPennies: 60000,
    },
    description: "Elite membership is a premium, annual membership subscription which not only includes opportunities for engagement, exclusive Infusion Center Locator features, and members-only resources and discounts, but also additional Elite-package features such as access to NICA’s library of reports, complimentary annual meeting registrations, and additional opportunities for engagement.<br/><br/> Elite Membership is priced per organization and one membership will include all current and future locations of the organization.",
    whatsIncludedLink: "https://infusioncenter.org/wp-content/uploads/2023/11/NICA-2024-Provider-Membership-Benefit-Overview-FINAL.pdf",
    allowMultiple: false,
};

export const MEMBERSHIP_TIER_SUPPORTING = {
    id: "supporting",
    name: "Supporting Membership",
    price: {
        annualInPennies: 30000,
    },
    description: "Supporting membership is a basic, annual membership subscription which includes opportunities for engagement, exclusive Infusion Center Locator features, and members-only resources and discounts.",
    whatsIncludedLink: "https://infusioncenter.org/wp-content/uploads/2023/11/NICA-2024-Provider-Membership-Benefit-Overview-FINAL.pdf",
    allowMultiple: true,
    maxQuantity: 24,
};

export const MEMBERSHIP_TIERS = [
    MEMBERSHIP_TIER_ELITE,
    MEMBERSHIP_TIER_SUPPORTING,
]

export const STEPS = [
    "Membership Tier",
    "Payment Options",
    "Sign in or Register",
    "Checkout"
]

export const PAYMENT_TYPE_ANNUAL = "annual";
export const PAYMENT_TYPE_MONTHLY = "monthly";

export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_ACH = "ach";
export const PAYMENT_METHOD_CHECK = "check";

export const PAYMENT_METHODS = [
    PAYMENT_METHOD_ACH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_CHECK,
]
