import { format } from "date-fns";
import numeral from "numeral";

import { MembershipTierType } from "../components/membershipTier";
import { PAYMENT_TYPE_ANNUAL } from "../api/data";

export function penniesToDollars(amountInPennies: number) {
    return amountInPennies / 100.0;
}

export function formatPennies(amountInPennies: number) {
    return numeral(penniesToDollars(amountInPennies)).format("$0,0.00");
}

export function proratedAmountInPennies(membership: MembershipTierType) {
    const dayInYear = parseInt(format(new Date(), "D", { useAdditionalDayOfYearTokens: true }), 10);
    return (membership.price.annualInPennies / 366.0) * (366 - dayInYear);
}

export function proratedSavingsInPennies(membership: MembershipTierType) {
    const proratedAmount = proratedAmountInPennies(membership);
    const monthInYear = parseInt(format(new Date(), "L"), 10);
    return (membership.price.monthlyInPennies || 0) * (13 - monthInYear) - proratedAmount;
}

export function savingsInPennies(membership: MembershipTierType) {
    return ((membership.price.monthlyInPennies || 0) * 12) - membership.price.annualInPennies;
}

export function earlybirdSubtotalInPennies(membership: MembershipTierType) {
    return proratedAmountInPennies(membership) + membership.price.annualInPennies;
}

export function convenienceFeeInPennies(subtotal: number) {
    return 0.03 * subtotal;
}

export function checkoutSubtotalInPennies(membership: MembershipTierType, earlyBirdSelected: boolean, quantity: number, paymentType: string) {
    let subtotal = 0;
    if (paymentType === PAYMENT_TYPE_ANNUAL) {
        subtotal += quantity * proratedAmountInPennies(membership);
        if (earlyBirdSelected) {
            subtotal += quantity * membership.price.annualInPennies;
        }
    } else {
        subtotal += quantity * (membership.price.monthlyInPennies || 0);
    }
    return subtotal;
}

export function discountAmountInPennies(discount: any, subtotalInPennies: number) {
    if (discount) {
        if (discount.type === 'FIXED') {
            return discount.discountAmount;
        }
        return parseInt((subtotalInPennies * (discount.discountAmount / 100.0)).toFixed(0), 10);
    }
    return 0;
}

export function checkoutTotalInPennies(subtotal: number, discountAmount: number, convenienceFee: number, includeConvenienceFee: boolean) {
    let total = subtotal - discountAmount;
    if (includeConvenienceFee) {
        total += convenienceFee;
    }
    return parseInt(total.toFixed(0), 10);
}

export function discountTitle(discountCode: any) {
    if (discountCode.type === 'FIXED') {
        return `${formatPennies(discountCode.discountAmount)}`
    }
    return `${discountCode.discountAmount}%`;
}
