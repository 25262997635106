import React, {HTMLAttributes} from 'react';

import api from "../../../api";

interface Props extends HTMLAttributes<HTMLDivElement> {
    eventName: string;
    params?: any;
}

const TrackableDiv: React.FC<Props> = ({eventName, params, onClick, ...other}) => {
    const internalOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        api.logEvent(eventName, params);
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <div {...other} onClick={internalOnClick} />
    );
};

export default TrackableDiv;
