import { createSelector } from "@reduxjs/toolkit";

export const paymentMethodsMapSelector = (state) => state.paymentMethods.paymentMethodsMap;
export const paymentMethodsLoadingSelector = (state) => state.paymentMethods.loading;
export const paymentMethodsSelector = () => createSelector(
    paymentMethodsMapSelector,
    (paymentMethodsMap) => {
        return paymentMethodsMap ? Object.values(paymentMethodsMap) : null;
    }
    );
