// import { initializeApp, getApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// import { getFirestore, collection, query as firestoreQuery, where, getDocs } from 'firebase/firestore';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import {
//     getAuth, signInWithEmailAndPassword, signInWithCustomToken, signOut, verifyPasswordResetCode, confirmPasswordReset,
//     sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider,
// } from 'firebase/auth';
// import { getDatabase, ref, get, orderByChild, startAfter, query, push, onValue } from 'firebase/database';
// import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import NicaApi from '@devsontap/nica-api';

firebase.initializeApp({
// initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

firebase.analytics();

// getAnalytics(app);
// connectFunctionsEmulator(getFunctions(app), "localhost", 5009);
// firebase.functions().useEmulator("localhost", 5009);

export function isLocal() {
    return document.domain === "localhost";
}

const api = new NicaApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage, firebase.analytics);

// api.loginWithEmailPassword = (email, password) => {
//     if (email && password) {
//         return signInWithEmailAndPassword(getAuth(getApp()), email, password);
//     }
//     return false;
// };

api.loginWithEmailPassword = (email, password) => {
    if (email && password) {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }
    return false;
};

api.loginWithGoogle = () => {
    // const provider = new GoogleAuthProvider();
    // return signInWithPopup(getAuth(getApp()), provider);
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
}

// api.loginWithToken = (token) => {
//     return signInWithCustomToken(getAuth(getApp()), token);
// }

api.loginWithToken = (token) => {
    return firebase.auth().signInWithCustomToken(token);
}

api.logout = () => {
    return firebase.auth().signOut();
    // return signOut(getAuth(getApp()))
    //     .then(() => {
    //         return true;
    //     });
};

// api.initiatePasswordReset = (email) => {
//     console.log("initiatePasswordReset", email);
//     if (email) {
//         return sendPasswordResetEmail(getAuth(getApp()), email);
//     }
//     return false;
// };

api.initiatePasswordReset = (email) => {
    console.log("initiatePasswordReset", email);
    if (email) {
        return firebase.auth().sendPasswordResetEmail(email);
    }
    return false;
};

api.verifyPasswordResetCode = (actionCode) => {
    return firebase.auth().verifyPasswordResetCode(actionCode);
    //return verifyPasswordResetCode(getAuth(getApp()), actionCode);
}

api.confirmPasswordReset = (actionCode, password) => {
    return firebase.auth().confirmPasswordReset(actionCode, password);
    // return confirmPasswordReset(getAuth(getApp()), actionCode, password);
}

api.registerUser = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
    // return createUserWithEmailAndPassword(getAuth(getApp()), email, password);
}

api.getPlaidLinkToken = async () => {
    return firebase.functions().httpsCallable("stripe-getPlaidLinkToken")();
    // return httpsCallable(getFunctions(getApp()), 'stripe-getPlaidLinkToken')();
}

api.exchangePlaidPublicToken = async (data) => {
    return firebase.functions().httpsCallable("stripe-exchangePlaidPublicToken")(data);
    // return httpsCallable(getFunctions(getApp()), '')(data)
}

api.checkout = (orgId, fullName, companyName, title, phoneNumber, phoneExt, membershipTier, quantity, earlyBirdSelected, paymentMethod, paymentType, autoRenew, totalInPennies, discountCode, paymentMethodId) => {
    return firebase.functions().httpsCallable("memberships-checkout")({
        orgId, fullName, companyName, title, phoneNumber, phoneExt, membershipTier, quantity, earlyBirdSelected,
        paymentMethod, paymentType, autoRenew, totalInPennies, discountCode, paymentMethodId,
    });
}

api.getOrganizations = async (email) => {
    // const snapshot = await getDocs(firestoreQuery(
    //     collection(getFirestore(getApp()), "organizations"),
    //     where("emails", "array-contains", email),
    // ));
    // return _docsToRecords(snapshot);
    return api.getUserOrganizations(email);
}

api.getPaymentMethods = async () => {
    return firebase.functions().httpsCallable("stripe-paymentMethods")();
};

api.getSetupIntent = async () => {
    return firebase.functions().httpsCallable("stripe-setupIntent")();
};

api.logEvent = (name, payload) => {
    firebase.analytics().logEvent(name, payload);
}

// function _docsToRecords(querySnapshot) {
//     const results = [];
//     querySnapshot.forEach(doc => results.push({
//         id: doc.id,
//         ...doc.data(),
//     }));
//     return results;
// }

export default api;
