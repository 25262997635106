import React from 'react';
import {Formik, Form} from "formik";
import {object, string} from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import {signInWithEmailPassword, signInWithGoogle} from "../../../redux/login";
import {loggingInSelector} from "../../../redux/login/selectors";
import arrowRight from "../../../img/arrow-right.svg";
import TrackableButton from "../../_common/trackables/TrackableButton";

const LoginForm = ({ setShowForgotPassword }) => {
    const loggingIn = useSelector(loggingInSelector);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <div>
                <div
                    className="text-sm md:text-lg uppercase text-center mb-2 font-hc tracking-wide text-primary font-medium font-oswald">
                    Sign In With Email
                </div>
                <div className="mt-2 mb-6 flex justify-center">
                    <TrackableButton eventName="login-with-google-click" variant="contained" color="inherit"
                        onClick={() => dispatch(signInWithGoogle(enqueueSnackbar))}>
                        <span className="font-oswald normal-case font-thin text-graydark">
                            or SIGN IN WITH GOOGLE
                        </span>
                    </TrackableButton>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={object().shape({
                        email: string().email("Please enter a valid email").required("Please enter a valid email"),
                        password: string().required("Please enter a password")
                    })}
                    onSubmit={({email, password}) => {
                        dispatch(signInWithEmailPassword(email, password, enqueueSnackbar));
                    }}>
                    <Form>
                        <div className="mb-4">
                            <CustomFormikField
                                name="email"
                                label="Email"
                                placeholder="john.smith@gmail.com"
                                CustomComponent={TextField}
                                variant="outlined"
                                fullWidth/>
                        </div>
                        <div className="mb-4">
                            <CustomFormikField
                                name="password"
                                label="Password"
                                type="password"
                                CustomComponent={TextField}
                                variant="outlined"
                                fullWidth/>
                        </div>
                        <div className="flex justify-end">
                            <div>
                                <Button variant="text" onClick={() => setShowForgotPassword(true)} color="secondary">
                                    Forgot Password?
                                </Button>
                            </div>
                        </div>
                        {loggingIn ?
                            <div className="flex justify-center">
                                <CircularProgress size={35}/>
                            </div> :
                            <>
                                <div className="mt-4 flex justify-center">
                                    <TrackableButton eventName="login-continue-to-checkout-click" variant="contained" color="primary" type="submit">
                                        <div className="font-oswald font-light flex items-center">
                                            <div>
                                                <span className="font-medium">Sign In</span> &amp; Continue To Checkout
                                            </div>
                                            <div className="ml-3">
                                                <img src={arrowRight} className="w-5 h-5" alt="Next"/>
                                            </div>
                                        </div>
                                    </TrackableButton>
                                </div>
                            </>
                        }
                    </Form>
                </Formik>
            </div>
        </>
    );
};

export default LoginForm;
