import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Standard dialog for showing prompts / alerts / messages
 * @param title     The title of the dialog
 * @param open      True if the dialog is open
 * @param onClose   Function for dismissing the dialog
 * @param children  Child components to render
 * @param actions   Items to render as Dialog Actions (usually buttons)
 */
const NICADialog = ({title, open, onClose, children, actions, maxWidth}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            maxWidth={maxWidth}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                {actions ? actions.map((action, index) => (
                    action.loading ?
                        <div className="flex justify-center items-center w-20"><CircularProgress size={30}/></div> :
                        <Button variant="text" color="primary" onClick={action.onClick} disabled={action.disabled}
                                key={`dialog-action-${index}`}>{action.label}</Button>
                )) : null}
            </DialogActions>
        </Dialog>
    )
};

NICADialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any,
    actions: PropTypes.array,
    maxWidth: PropTypes.string
};

NICADialog.defaultProps = {
    maxWidth: "sm"
};

export default NICADialog;
