import React from 'react';
import {ButtonProps, Button} from "@mui/material";

import api from "../../../api";

interface Props extends ButtonProps {
    eventName: string;
    params?: any;
}

const TrackableButton: React.FC<Props> = ({eventName, params, onClick, ...other}) => {
    const internalOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        api.logEvent(eventName, params);
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <Button {...other} onClick={internalOnClick} />
    );
};

export default TrackableButton;
