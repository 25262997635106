import React, {useEffect, useState, useMemo} from 'react';
import {Card, Switch, TextField, FormControlLabel, Checkbox, CircularProgress} from "@mui/material";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {addMonths, format} from "date-fns";
import {useForm} from "react-hook-form";
// @ts-ignore
import {PaymentMethodType} from "@devsontap/nica-api/core/models/enums";

import {
    checkout,
    loadDiscountCode, discountCodeSuccess
} from "../../redux/checkout";
import {
    checkingOutSelector, discountCodeLoadingSelector, discountCodeSelector,
} from "../../redux/checkout/selectors";
import {
    checkoutSubtotalInPennies, checkoutTotalInPennies,
    convenienceFeeInPennies, discountAmountInPennies, discountTitle,
    formatPennies,
    proratedAmountInPennies
} from "../../utils/money";
import {MembershipTierType} from "../membershipTier";
import {PAYMENT_TYPE_ANNUAL, PAYMENT_TYPE_MONTHLY} from "../../api/data";

import cart from "../../img/cart.svg";
import cx from "classnames";
import {claimsSelector} from "../../redux/login/selectors";
import OrgSelect from "../_common/orgSelect";
import PhoneNumberTextField from "../_common/textFields/phoneNumber";
import PaymentMethodList from "../_common/paymentMethodList";
import {paymentMethodsSelector} from "../../redux/paymentMethods/selectors";
import api from "../../api";
import TrackableButton from "../_common/trackables/TrackableButton";

interface Props {
    autoRenew: boolean;
    setAutoRenew: (autoRenew: boolean) => void;
    selectedTier: MembershipTierType;
    quantity: number;
    earlyBirdSelected: boolean;
    selectedPaymentType: string;
}

const Checkout: React.FC<Props> = ({ autoRenew, setAutoRenew, selectedTier, quantity, earlyBirdSelected, selectedPaymentType, }) => {
    const [paymentMethodId, setPaymentMethodId] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const checkingOut = useSelector(checkingOutSelector);
    const discountCodeLoading = useSelector(discountCodeLoadingSelector);
    const discountCode = useSelector(discountCodeSelector);
    const { orgIds } = useSelector(claimsSelector);
    const paymentMethods = useSelector(paymentMethodsSelector());
    const [orgId, setOrgId] = useState(orgIds ? orgIds[0] : null);
    const dispatch = useDispatch();
    const form = useForm();

    // Reset the discount code when this component is first rendered
    useEffect(() => {
        // @ts-ignore
        dispatch(discountCodeSuccess(null))
    }, [dispatch]);

    const onSubmit = (values: any) => {
        const {fullName, title, phoneNumber, phoneExt, companyName}: {fullName: string, title: string, phoneNumber: string, phoneExt: string, companyName: string} = values;
        onCheckout(fullName, companyName, title, phoneNumber, phoneExt, paymentMethod);
    }

    const onCheckout = (fullName: string, companyName: string, title: string, phoneNumber: string, phoneExt: string, paymentMethod: string,) => {
        const totalInPennies = checkoutTotalInPennies(checkoutSubtotal, discountAmount, convenienceFee, paymentMethod === PaymentMethodType.CREDIT.value);
        // @ts-ignore
        dispatch(checkout(orgId, fullName, companyName, title, phoneNumber, phoneExt, selectedTier, quantity, earlyBirdSelected, paymentMethod, selectedPaymentType, autoRenew, totalInPennies, discountCode, paymentMethodId, enqueueSnackbar));
    }

    const onAddDiscountCode = (slug: string) => {
        if (slug.length > 0) {
            // @ts-ignore
            dispatch(loadDiscountCode(slug, selectedTier.id, enqueueSnackbar));
        }
    }

    const checkoutSubtotal = useMemo(() => {
        return checkoutSubtotalInPennies(selectedTier, earlyBirdSelected, quantity, selectedPaymentType);
    }, [selectedTier, earlyBirdSelected, quantity, selectedPaymentType]);

    const discountAmount = useMemo(() => {
        return discountAmountInPennies(discountCode, checkoutSubtotal);
    }, [discountCode, checkoutSubtotal]);

    const convenienceFee = useMemo(() => {
        return convenienceFeeInPennies(checkoutSubtotal - discountAmount);
    }, [checkoutSubtotal, discountAmount]);

    const paymentMethod: string = useMemo(() => {
        if (paymentMethodId === PaymentMethodType.CHECK.value) {
            return paymentMethodId;
        }

        const method = paymentMethods?.find((method: any) => method.id === paymentMethodId);

        if (method?.card) {
            return PaymentMethodType.CREDIT.value;
        }
        return PaymentMethodType.ACH.value;
    }, [paymentMethodId, paymentMethods]);

    const confirmCheckPayment = form.watch("confirmCheckPayment");

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div>
                <Card className="py-4 px-3 md:px-10 w-full md:w-[600px]">
                    <div className="text-center text-primary uppercase text-sm md:text-lg font-medium mb-4">
                        Contact Info
                    </div>
                    {orgIds?.length > 0 &&
                        <div className="mb-2 md:mb-4">
                            <OrgSelect
                                value={orgId}
                                onChange={e => {
                                    api.logEvent("org-changed");
                                    setOrgId(e.target.value);
                                }}
                                fullWidth />
                        </div>
                    }
                    <div className="mb-2 md:mb-4">
                        <TextField
                            {...form.register("fullName", { required: "Please enter your name." })}
                            variant="outlined"
                            label="Full Name"
                            placeholder="John Smith"
                            error={Boolean(form.formState.errors.fullName)}
                            helperText={form.formState.errors.fullName?.message?.toString()}
                            fullWidth />
                    </div>
                    <div className="mb-2 md:mb-4">
                        <TextField
                            {...form.register("companyName", { required: "Please enter your Company" })}
                            variant="outlined"
                            label="Company"
                            placeholder="My Infusion Center"
                            error={Boolean(form.formState.errors.companyName)}
                            helperText={form.formState.errors.companyName?.message?.toString()}
                            fullWidth />
                    </div>
                    <div>
                        <TextField
                            {...form.register("title", { required: "Please enter your Title" })}
                            variant="outlined"
                            label="Title"
                            placeholder="Senior Director, Alphabet"
                            error={Boolean(form.formState.errors.title)}
                            helperText={form.formState.errors.title?.message?.toString()}
                            fullWidth />
                    </div>
                    <div className="text-graydark text-sm font-light mt-1 pl-3 mb-3">
                        e.g. Job Title, Company
                    </div>
                    <div className="mb-6 flex">
                        <div className="flex-1">
                            {/* @ts-ignore */}
                            <PhoneNumberTextField
                                {...form.register("phoneNumber", { required: "Please enter a valid phone number.", minLength: 10, maxLength: 10 })}
                                variant="outlined"
                                label="Direct Phone Number"
                                placeholder="(555) 555-5555"
                                error={Boolean(form.formState.errors.phoneNumber)}
                                helperText={form.formState.errors.phoneNumber?.message?.toString()}
                                setValue={form.setValue}
                                fullWidth />
                        </div>
                        <div className="pl-4">
                            <TextField
                                {...form.register("phoneExt", {})}
                                variant="outlined"
                                label="Ext"
                                placeholder="3245" />
                        </div>
                    </div>
                    <div className="border-solid border-0 border-t border-graymedium pt-6">
                        <div className="text-center text-primary uppercase text-sm md:text-lg font-medium mb-3">
                            Payment Method
                        </div>
                        <div>
                            <PaymentMethodList selectedPaymentMethodId={paymentMethodId} onChange={methodId => setPaymentMethodId(methodId)} />
                        </div>
                        <div className="pt-3 md:pb-2">
                            <div className={cx({
                                "hidden": paymentMethod !== PaymentMethodType.CREDIT.value
                            })}>
                                <div className="font-lato text-graydark text-center mb-2 text-xs md:text-base">
                                    A 3% convenience fee is applied when paying by card.
                                </div>
                            </div>
                            {paymentMethod === PaymentMethodType.CHECK.value &&
                                <div className="bg-graylight rounded-md py-3 px-4 font-lato text-xs md:text-sm mt-3 text-graydark">
                                    <div className="font-oswald uppercase text-sm md:text-base mb-1">
                                        Pay By Check Terms
                                    </div>
                                    <div>
                                        I understand that these purchases will not be active until NICA receives payment in
                                        full. Returned checks will incur a $30.00 fee.
                                    </div>
                                    <div className="border-solid border-0 border-l border-graydark pl-3 my-3">
                                        <div className="font-bold mb-1">
                                            Send a check or money order to:
                                        </div>
                                        <div>
                                            National Infusion Center Association
                                        </div>
                                        <div>
                                            3307 Northland Dr #160
                                        </div>
                                        <div>
                                            Austin, TX 78731
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={confirmCheckPayment} onChange={e => {
                                                    api.logEvent("confirm-check-payment-click")
                                                    form.setValue('confirmCheckPayment', (e.target.checked));
                                                }} />
                                            }
                                            label={
                                                <span className="uppercase font-oswald text-primary text-xs md:text-base">I agree</span>
                                            } />
                                    </div>
                                    {form.formState.errors.confirmCheckPayment &&
                                        <div className="text-red italic text-sm mt-1">
                                            {form.formState.errors.confirmCheckPayment?.message?.toString()}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="border-solid border-0 border-t border-graymedium mt-6 pt-6">
                        <div className="text-center text-primary uppercase text-sm md:text-lg font-medium mb-3">
                            Discount Code
                        </div>
                        <div className="pt-3 md:pb-2 flex">
                            <div className="flex-1">
                                <TextField
                                    {...form.register("discountCode")}
                                    name="discountCode"
                                    variant="outlined"
                                    label="Enter Discount Code"
                                    placeholder="3kfi3ssd-nica"
                                    fullWidth />
                            </div>
                            <div className="flex items-center justify-center w-20">
                                {discountCodeLoading ?
                                    <CircularProgress size={35} /> :
                                    <TrackableButton eventName="add-discount-code-click" variant="text" color="primary" onClick={() => {
                                        console.log("discountCode", form.getValues('discountCode'));
                                        onAddDiscountCode(form.getValues('discountCode'));
                                        form.setValue('discountCode', '');
                                    }}>
                                        + Add
                                    </TrackableButton>
                                }
                            </div>
                        </div>
                    </div>
                </Card>
                <div className="mt-4 rounded-md border-solid border-graymedium border-[1px] w-full md:w-[600px] text-graydark font-lato text-sm">
                    <div className="p-3">
                        <div className="flex items-center mb-2">
                            <div>
                                <img src={cart} className="h-4 w-4 md:h-5 md:w-5" alt="Cart" />
                            </div>
                            <div className="uppercase ml-2 font-oswald text-base md:text-lg">
                                {selectedTier.name}{quantity > 1 && ` (x${quantity})`}
                            </div>
                        </div>
                        {earlyBirdSelected && selectedPaymentType === PAYMENT_TYPE_ANNUAL &&
                            <div className="pb-2 mb-2 border-solid border-0 border-b border-graymedium text-base">
                                <div className="flex">
                                    <div className="flex-1">
                                        {selectedTier.name} {new Date().getFullYear()} (Prorated)
                                    </div>
                                    <div>
                                        {formatPennies(proratedAmountInPennies(selectedTier) * quantity)}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-1">
                                        {selectedTier.name} {new Date().getFullYear() + 1} (Early Bird Special)
                                    </div>
                                    <div>
                                        {formatPennies(selectedTier.price.annualInPennies * quantity)}
                                    </div>
                                </div>
                            </div>
                        }
                        {(paymentMethod === PaymentMethodType.CREDIT.value || discountCode) &&
                            <div className="text-sm md:text-base">
                                <div className="flex">
                                    <div className="flex-1">
                                        Subtotal
                                    </div>
                                    <div>
                                        {formatPennies(checkoutSubtotalInPennies(selectedTier, earlyBirdSelected, quantity, selectedPaymentType))}
                                    </div>
                                </div>
                                {discountCode &&
                                    <div className="flex">
                                        <div className="flex-1">
                                            Discount - {discountTitle(discountCode)} off
                                        </div>
                                        <div>
                                            ({formatPennies(discountAmountInPennies(discountCode, checkoutSubtotalInPennies(selectedTier, earlyBirdSelected, quantity, selectedPaymentType)))})
                                        </div>
                                    </div>
                                }
                                {paymentMethod === PaymentMethodType.CREDIT.value &&
                                    <div className="flex">
                                        <div className="flex-1">
                                            3% convenience fee
                                        </div>
                                        <div>
                                            {formatPennies(convenienceFee)}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="flex font-bold text-sm md:text-base">
                            <div className="flex-1">
                                Total
                            </div>
                            <div className="text-sm md:text-base">
                                {formatPennies(checkoutTotalInPennies(checkoutSubtotal, discountAmount, convenienceFee, paymentMethod === PaymentMethodType.CREDIT.value))}
                            </div>
                        </div>
                    </div>
                    <div className="border-solid border-0 border-t border-graymedium p-2">
                        <div className="rounded-md bg-white text-graydark uppercase py-1 px-2 mb-2 mt-1 font-oswald font-light text-sm md:text-lg">
                            Membership Valid Today -&nbsp;
                            {selectedPaymentType === PAYMENT_TYPE_MONTHLY ?
                                format(addMonths(new Date(), 1), "MMM dd, yyyy") :
                                `Dec 31 ${new Date().getFullYear() + (earlyBirdSelected ? 1 : 0)}`
                            }
                        </div>
                        <div className="px-2">
                            <div className="flex items-center">
                                <div className="flex-1 font-bold text-sm md:text-base">
                                    Auto Renew Payments
                                </div>
                                <div>
                                    <Switch
                                        checked={autoRenew && paymentMethod !== PaymentMethodType.CHECK.value}
                                        onChange={e => {
                                            if (selectedPaymentType !== PAYMENT_TYPE_MONTHLY) {
                                                api.logEvent('checkout-auto-renew-click', { checked: e.target.checked });
                                                setAutoRenew(e.target.checked);
                                            }
                                        }} />
                                </div>
                            </div>
                            {autoRenew &&
                                (paymentMethod !== PaymentMethodType.CHECK.value ?
                                        <>
                                            <div className="mt-2 text-xs md:text-base">
                                                The same Payment Method will be charged for all subsequent payments. Subsequent payment
                                                amounts depend on the membership price at the date of payment.
                                            </div>
                                            <div className="mt-2 text-xs md:text-base">
                                                The first Auto Renew payment will be made on&nbsp;
                                                {selectedPaymentType === PAYMENT_TYPE_MONTHLY ?
                                                    format(addMonths(new Date(), 1), "MMM dd, yyyy") :
                                                    `Jan 1, ${new Date().getFullYear() + (earlyBirdSelected ? 2: 1)}`
                                                }
                                            </div>
                                        </> :
                                        <div className="text-xs md:text-base">
                                            Check Payments cannot be auto-renewed.
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-6 mb-12">
                    {checkingOut ?
                        <CircularProgress size={35} /> :
                        <TrackableButton eventName="checkout-submit-payment-click" color="success" variant="contained" type="submit" disabled={!paymentMethodId || (paymentMethodId === PaymentMethodType.CHECK.value && !confirmCheckPayment)}>
                            <div className="font-lato">
                                <strong>Submit Payment</strong>&nbsp;{formatPennies(checkoutTotalInPennies(checkoutSubtotal, discountAmount, convenienceFee, paymentMethod === PaymentMethodType.CREDIT.value))}
                            </div>
                        </TrackableButton>
                    }
                </div>
            </div>
        </form>
    );
}

export default Checkout;
