import React, {useEffect} from 'react';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { SelectProps } from "@mui/material/Select";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import {loadOrganizations} from "../../../redux/organizations";
import {orgsSelector, orgsLoadingSelector} from "../../../redux/organizations/selectors";
import {claimsSelector} from "../../../redux/login/selectors";

const OrgSelect: React.FC<SelectProps> = ({value, onChange, fullWidth, disabled}) => {
    const orgs = useSelector(orgsSelector());
    const orgsLoading = useSelector(orgsLoadingSelector);
    const { email } = useSelector(claimsSelector);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!orgs || !orgs.length) {
            // @ts-ignore
            dispatch(loadOrganizations(email, enqueueSnackbar));
        }
    }, [dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        orgsLoading || !orgs ?
            <CircularProgress size={30} />
            :
            <FormControl variant="outlined" disabled={disabled} fullWidth={fullWidth}>
                <InputLabel variant="outlined" id="organizationLabel">Organization</InputLabel>
                <Select
                    name="organization"
                    value={value}
                    variant="outlined"
                    onChange={onChange}
                    label="Organization"
                    labelId="organizationLabel"
                    fullWidth={fullWidth}>
                    {orgs.map((org: any)=> {
                        return (
                            <MenuItem value={org.id} key={org.id}>{org.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
    )
};

export default OrgSelect;
