import React from 'react';
import cx from 'classnames';

// @ts-ignore
import checkboxOff from "../../../img/checkbox-off.svg";
// @ts-ignore
import checkboxOn from "../../../img/checkbox-on.svg";

interface Props {
    selected: boolean;
    children: any;
    onSelected: () => void;
}

const NicaRadioButton: React.FC<Props> = ({ selected = false, children, onSelected }) => {
    return (
        <div className={cx("flex p-3 md:p-4 cursor-pointer border-2 rounded-md border-solid", {
            "border-primary": selected,
            "border-transparent": !selected
        })} onClick={onSelected}>
            <div className="pt-1">
                <img src={selected ? checkboxOn : checkboxOff} alt="checkbox" className="w-5 h-5" />
            </div>
            <div className="flex-1 ml-3 md:mr-4">
                {children}
            </div>
        </div>
    )
}

export default NicaRadioButton;
