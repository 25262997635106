import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: '#1c5c7f',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#53c9e8',
            contrastText: '#ffffff',
        },
        success: {
            main: "#36C68A",
            contrastText: "#ffffff"
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                selected: {
                    fontWeight: 500,
                },
                root: {
                    fontFamily: "Oswald",
                    color: "#777777",
                    fontWeight: 400,
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                scrollButtons: {
                    // color: "#ffffff"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    fontFamily: "Oswald",
                    fontWeight: 300,
                },
                textInherit: {
                    fontFamily: "Lato",
                    textTransform: "none",
                    // fontWeight: 300,
                },
                textSecondary: {
                    fontFamily: "Lato",
                    textTransform: "none",
                    // fontWeight: 300,
                },
            }
        }
    }
});